import React, { Component } from 'react'
import Slider from 'react-slick';
import style from './masthead.module.scss';
// Import neccessary site components, try to maintain alphabetical order
import Background from 'components/background/';
import Icon from 'components/icon';
import Link from 'components/link';

import SearchInput from '../searchinput';

import jQuery from 'jquery';

const PrevArrow = (props) => {
	const { className, onClick } = props;
	return (
		<div onClick={onClick}>
			<Icon
				icon="arrowLeft"
				className={className}
			/>
		</div>
	);
}

const NextArrow = (props) => {
	const { className, onClick } = props;
	return (
		<div onClick={onClick}>
			<Icon
				icon="arrowRight"
				className={className}
			/>
		</div>
	);
}

var waitUntilDone = (function () {
	var timers = {};
	return function (callback, ms, uniqueId) {
		if (!uniqueId) {
			uniqueId = "Don't call this twice without a uniqueId";
		}
		if (timers[uniqueId]) {
			clearTimeout(timers[uniqueId]);
		}
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();

var MastheadVideo = function () {
	var self = this;
	var $ = jQuery;

	self.selectors = {
		hero: 'data-hero-video'
	}

	self.bindEvents = function () {
		$(function () {
			self.sizeHero();
		});
		$(document).on('video-hero-resized', function () {
			self.sizeVideos();
		});
		$(window).on("load", function () {
			self.sizeVideos();
		});
		$(window).on("resize", function () {
			waitUntilDone(function () {
				self.sizeHero();
			}, 300, "resizing-video-hero");
		});
	}

	/**
	* Size the Hero Container
	*/
	self.sizeHero = function () {
		$('[' + self.selectors.hero + ']').css('height', '');
		var height = $(window).height() - $('header.top').outerHeight() - $('header.primary').outerHeight();
		if ($(window).height() > 850) height = height - $('.home-quick-links').outerHeight();
		if ($(window).height() < 680) height = height + 100;
		$('[' + self.selectors.hero + ']').height(height);
		$(document).trigger('video-hero-resized');
	}

	/**
	* Size the Videos Proportionally to fill the Container
	*/
	self.sizeVideos = function () {
		var containers = $('[' + self.selectors.hero + ']');
		$.each(containers, function () {
			var video = $(this).find('video');
			var videoRatio = $(video).width() / $(video).height();
			var containerRatio = $(this).width() / $(this).height();
			var val;
			if (videoRatio < containerRatio) {
				val = containerRatio / videoRatio * 1.02;
			} else if (containerRatio < videoRatio) {
				val = videoRatio / containerRatio * 1.02;
			}
			$(video).css('transform', 'scale(' + val + ')');
		});
	}

	return self.bindEvents();
}

class Masthead extends Component {
	constructor(props) {
		super(props);
		this.changeHandler = this.changeHandler.bind(this);
		this.state = {
			currentSlide: 1,
			totalSlides: props.data.length,
			searchQuery: '',
		};
		this.leftArrowClick = this.leftArrowClick.bind(this);
		this.rightArrowClick = this.rightArrowClick.bind(this);
		this.searchQuery = {};
	}

	componentDidMount() {
		jQuery(function () {
			new MastheadVideo();
		});
		if (this.props.searchQuery) {
			this.searchQuery = this.props.searchQuery;
		}
		this.setState({
			searchQuery: this.searchQuery,
		});
	}

	changeHandler(e) {
		let {options, name, value, selectedIndex} = e.target;
		if(this.searchQuery) {
			this.searchQuery = {...this.state.searchQuery, ...{[name]: value}}
			this.setState({searchQuery: this.searchQuery});
		} else {
			this.setState({
				searchQuery: {[name]: value},
			})
		}
	}

	leftArrowClick() {
		this.slider.slickPrev();
	}

	rightArrowClick() {
		this.slider.slickNext();
	}

	render() {
		const settings = {
			dots: true,
			dotsClass: style.pagination,
			customPaging: function (i) {
				return (
					<a>
						<Icon icon="diamond" foreground="#ffffff" />
					</a>
				);
			},
			arrows: false,
			autoplay: true,
			speed: 1500,
			autoplaySpeed: 4000,
			ref: slider => (this.slider = slider),
			beforeChange: (current, next) => {
				this.setState({ currentSlide: next + 1 })
				new MastheadVideo();
			},
		}
		return (
			<>
			<div className={style.mastheadWrapper}>
				{   this.props.data.length > 1 &&
					<div className={style.arrows}>
						<PrevArrow onClick={this.leftArrowClick} className={`${style.arrow} ${style.prev}`} />
						<NextArrow onClick={this.rightArrowClick} className={`${style.arrow} ${style.next}`} />
					</div>
				}
				<Slider {...settings}>
					{this.props.data.map((slide, index) => (
						!slide.video ?
						<div key={index} className={style.container}>
							<Background className={style.background} image={slide.image}>
								<div className={`grid-container`}>
									<div className="grid-x grid-margin-x">
										<div className="cell small-12 medium-5">
											<Background className={style.headline} overlay="linear-gradient(180deg, rgba(68,85,90,0) 0%, #050505 100%)" style={{ backgroundRepeat: "repeat", backgroundSize: "auto", width: "calc(100% + 66px)" }} image="Repeatable-Texture.png">
												<div className={style.headlineWrapper}>
													<span>{slide.title}</span>
													<h1>{slide.description}</h1>
													<div className={[style.subline, "text-right"].join(" ")}>
														{ slide.link && (
															<Link className="button small" href={slide.link}>{slide.linkText}</Link>
														)}
													</div>
												</div>
											</Background>
										</div>
									</div>
								</div>
							</Background>
						</div>
						:
						<div key={index} className={`${style.container} ${style.video}`} data-hero-video>
							<video className={`${style.background} ${style.vid}`} loop muted autoPlay>
								<source src={slide.video} type="video/mp4" />
							</video>
							<div className={`${style.background}`}>
								<div className={`grid-container`}>
									<div className="grid-x grid-margin-x">
										<div className="cell small-12 medium-5">
											<Background className={style.headline} overlay="linear-gradient(180deg, rgba(68,85,90,0) 0%, #050505 100%)" style={{ backgroundRepeat: "repeat", backgroundSize: "auto", width: "calc(100% + 66px)" }} image="Repeatable-Texture.png">
												<div className={style.headlineWrapper}>
													<span>{slide.title}</span>
													<h1>{slide.description}</h1>
													<div className={[style.subline, "text-right"].join(" ")}>
														{ slide.link && (
															<Link className="button small" href={slide.link}>{slide.linkText}</Link>
														)}
													</div>
												</div>
											</Background>
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</Slider>
			</div>
			<div style={{ border: "1px solid #6E6E6E", marginTop: "-6px", borderLeft: "none", borderRight: "none" }}>
				<div className={[style.searchBox, "grid-container"].join(" ")}>
					<div className="grid-x grid-margin-x" style={{ alignItems: 'center' }}>
						<div className="cell small-12 medium-3">
							<p style={{ color: "#2D8077" }}>Looking for something specific?</p>
						</div>
						<div className={[style.searchContainer, "cell small-12 medium-7"].join(' ')}>
							<SearchInput className={style.searchField} changeHandler={this.changeHandler} focusState={this.props.focusState} name="terms" label="Search" value={this.state.searchQuery && this.state.searchQuery.terms ? decodeURI(this.state.searchQuery.terms) : ''} />
							<Link state={this.state.searchQuery} className={[style.searchButton, 'button'].join(' ')} to="/search/">Submit</Link>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}

export default Masthead;