import React from "react"

import Layout from "../templates/home/"
import SEO from '../components/seo'
import Blockquote from '../components/blockquote/';
import Events from '../components/events/';
import EventsSlideController from 'controllers/events/slide'
import SectionCallout from '../components/sectioncallout/';
import NewsReports from '../components/newsreports/';
import { graphql } from 'gatsby';
import zparse from 'helpers/parse';


const IndexPage = ({ data, pageContext }) => {
	let page = null;
	let services = [];
	if (data.allContentPage && data.allContentPage.edges.length) page = data.allContentPage.edges[0].node;
	if (data.allServicesService && data.allServicesService.edges.length) services = data.allServicesService.edges;
	if (services.length) {
		services = services.map((service) => {
			service.node.link = service.node.uri + '#service_portfolio';
			return service.node;
		})
	}

	return (
		<>
			<Layout preTitle={page.pageTitle} title={page.subTitle} link={page.link} linkText={page.linkText} enableDanosFoundation={page.danosFoundation} enableFooterTabs={page.danosFooterTab} enableBlogFeed={true}>
				<section className="intro">
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12 medium-6">
								<h1>{page.headline}</h1>
								{zparse(page.pageCopy)}
							</div>
							<div className="cell small-12 medium-offset-1 medium-5">
								<Blockquote>{page.pullQuote}</Blockquote>
							</div>
						</div>
					</div>
				</section>

				<section id="portfolio">
					<div className="grid-container small-full">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								<h2 style={{ paddingBottom: "77px" }} className="text-center">View our work</h2>
							</div>
						</div>
						<SectionCallout data={services} arrows={true} limitWords={true} hasCTA={true} />
					</div>
				</section>

				<NewsReports />

				<EventsSlideController title="Scheduled Events" description="" backgroundColor="F4F4F4" />

			</Layout>
			<SEO title="Danos Does it Right." />
		</>
	)
}

export const query = graphql`
	query ($lang: String) {
	  allServicesService(filter: {deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
		edges {
			node {
				id
				preTitle
				title
				permalink
				description
				photo
				icon
				uri
			}
		}
	  }
	  allContentPage(filter: {uri: {eq: "/"}, lang: {eq: $lang}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
			lang
	        pageTitle
			subTitle
			pullQuote
			pageCopy
			headline
			link
			linkText
			danosFoundation
			danosFooterTab
	      }
	    }
	  }
	}
`

export default IndexPage