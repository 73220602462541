/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */


/*

<link rel="stylesheet" href="https://use.typekit.net/esh4svg.css">

 */

import React, { Component } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"

import Footer from '../../components/footer/';
import Header from '../../components/header/';
import style from './home.module.scss';
import SEO from '../../components/seo';
import Background from '../../components/background';
import Offcanvas from '../../components/offcanvas/';
import Link from '../../components/link';
import MastheadSlideController from 'controllers/masthead/slide';


class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		}
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}

	componentWillUnmount() {
		this.setState({ isOpen: false });
	}

	open() {
		this.setState({ isOpen: true });
	}

	close() {
		this.setState({ isOpen: false });
	}

	render() {
		return (
			<>
				<SEO>
					<link rel="stylesheet" href="https://use.typekit.net/uac4quh.css" />
				</SEO>
				<Offcanvas isOpen={this.state.isOpen} offcanvasOpen={this.open} offcanvasClose={this.close}>
					<Header spacer={false} offcanvasOpen={this.open} offcanvasClose={this.close} offcanvasState={this.state.isOpen} />
					<MastheadSlideController />
					<main>{this.props.children}</main>
					<Footer enableBlogFeed={this.props.enableBlogFeed} enableDanosFoundation={this.props.enableDanosFoundation} enableFooterTabs={this.props.enableFooterTabs} />
				</Offcanvas>
			</>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout