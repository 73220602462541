import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby';
// Import neccessary site components, try to maintain alphabetical order
import Events from 'components/events/';

const STATUS = {
	INITIAL: 1,
	LOADING: 2,
	LOADED: 3,
	FAIL: 4,
}

class EventsSlideController extends Component {
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}events/event/all/?_join=true&_sort=startDate&_order=ASC`)
			.then(response => response.json())
			.then(({ data }) => {
				if (data.length === 0) data = []
				this.setState({
					status: STATUS.LOADED,
					data: data,
				})
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);

		let initialData = [];
		this.state = {
			status: STATUS.INITIAL,
			data: initialData,
		}
		this.liveRefresh = this.liveRefresh.bind(this);
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		if (this.state.data.length === 0) return null;
		return (
			<Events data={this.state.data} title={this.props.title} description={this.props.description} backgroundColor={this.props.backgroundColor} />
		);
	}
}

export default EventsSlideController;